<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Presenze</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="openMenu()">
                        <ion-icon :icon="menu" slot="start" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button @click="loadTimbrature()">
                        <ion-icon slot="start" :icon="refresh" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true" ionScroll="scrollFunction($event)">
            <div v-if="loading" class="loading_spinner">
                <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>

            <div class="page_content" v-else>
                <!-- QRCode modal -->
                <Transition name="fade-transition">
                    <div v-if="showQrCode" class="modal_straordinario">
                        <div class="input_container">
                            <div class="title_qr">Inquadra il codice QR per timbrare</div>
                            <div class="qr_code">
                                <qr-stream @decode="onDecode" class="mb">
                                    <div class="frame"></div>
                                </qr-stream>
                            </div>

                            <div class="confirmation_text">
                                {{ confirmationText }}
                            </div>

                            <button @click="handleQrCodeTimbratura()" class="btn_salva_straordinario" :disabled="disabledQrCodeButton">Salva</button>
                            <button @click="chiudiQrCode()" class="btn_chiudi_straordinario">Chiudi</button>
                        </div>
                    </div>
                </Transition>
                <!-- Straordinario modal -->
                <!-- <Transition name="fade-transition">
                <div v-if="showStraordinario" class="modal_straordinario">
                    <div class="input_container">
                        <div class="title">Personalizza lo straordinario effettuato</div>
                        <ion-input v-model="selectedPresenza.presenze_straordinario" type="number"> </ion-input>
                        <div @click="salvaStraordinario()" class="btn_salva_straordinario">Salva</div>
                        <div @click="chiudiStraordinario()" class="btn_chiudi_straordinario">Chiudi</div>
                    </div>
                </div>
            </Transition> -->
                <Transition name="fade-transition">
                    <div v-if="showStraordinario" class="modal_straordinario">
                        <div class="input_container">
                            <div v-if="dipendenteSettings.dipendenti_consenti_straordinari === '1'" class="field">
                                <div class="title">Straordinario effettuato</div>
                                <ion-input v-model="selectedPresenza.presenze_straordinario" type="number"> </ion-input>
                            </div>
                            <div class="field">
                                <div class="title">Note</div>
                                <ion-textarea v-model="selectedPresenza.presenze_note" rows="4" placeholder="Informazioni presenza..."> </ion-textarea>
                            </div>
                            <!-- <div @click="deletePresenza(selectedPresenza.presenze_id)" class="btn_chiudi_straordinario">Elimina presenza</div> -->
                            <div @click="editPresenza()" class="btn_salva_straordinario">Salva</div>
                            <div @click="chiudiStraordinario()" class="btn_chiudi_straordinario">Chiudi</div>
                        </div>
                    </div>
                </Transition>

                <!-- QRCode modal -->
                <Transition name="fade-transition">
                    <div v-if="showNfcContainer" class="modal_nfc">
                        <div class="nfc_container">
                            <div class="title_nfc">Pronto per la scansione</div>
                            <div class="svg_container">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#086fa3" stroke="none">
                                        <path
                                            d="M4030 5101 c-71 -37 -98 -117 -66 -195 18 -44 54 -67 155 -99 209
                                        -68 394 -198 522 -366 62 -82 141 -238 170 -334 26 -87 51 -125 95 -143 78
                                        -32 158 -5 195 66 26 48 24 83 -11 192 -135 411 -457 733 -868 868 -109 35
                                        -144 37 -192 11z"
                                        />
                                        <path
                                            d="M2378 4929 c-558 -43 -1084 -286 -1489 -689 -314 -312 -529 -688
                                        -634 -1109 -159 -635 -44 -1313 313 -1856 108 -166 186 -261 321 -395 595
                                        -591 1438 -826 2241 -625 783 196 1420 785 1679 1551 89 262 124 476 124 754
                                        0 375 -73 694 -239 1035 -116 240 -269 452 -468 650 -406 404 -927 641 -1506
                                        685 -149 11 -186 11 -342 -1z m-1233 -1748 c36 -16 67 -56 260 -336 l220 -318
                                        5 286 c5 269 6 287 26 313 38 51 71 69 128 69 66 0 120 -36 136 -93 8 -25 10
                                        -205 8 -563 l-3 -526 -27 -35 c-33 -44 -102 -73 -150 -64 -69 13 -92 39 -304
                                        347 l-209 303 -5 -286 c-5 -268 -6 -288 -25 -314 -65 -86 -181 -82 -249 10
                                        -20 27 -21 40 -24 539 -2 282 0 531 3 555 11 72 75 128 150 131 11 0 38 -8 60
                                        -18z m1711 -7 c94 -70 96 -190 5 -251 -31 -21 -45 -23 -182 -23 l-149 0 0 -94
                                        0 -94 134 -4 c145 -3 164 -10 208 -69 48 -64 23 -166 -51 -207 -34 -19 -54
                                        -22 -164 -22 l-126 0 -3 -202 c-3 -194 -4 -204 -26 -236 -30 -42 -76 -65 -130
                                        -65 -49 0 -85 22 -119 73 l-23 33 0 534 c0 575 -1 561 53 611 13 13 37 27 53
                                        32 16 5 133 8 261 7 217 -2 233 -3 259 -23z m1154 0 c77 -23 186 -81 215 -116
                                        68 -81 26 -207 -80 -237 -44 -12 -64 -7 -154 37 -68 34 -81 37 -160 37 -71 -1
                                        -96 -5 -142 -27 -79 -36 -133 -89 -171 -166 -29 -59 -33 -76 -33 -152 0 -76 3
                                        -92 33 -152 96 -196 348 -259 498 -125 62 56 84 67 129 67 76 0 135 -54 143
                                        -130 6 -67 -9 -100 -79 -164 -132 -123 -323 -171 -514 -131 -184 38 -360 176
                                        -444 346 -94 191 -94 387 0 579 82 166 257 304 439 345 80 18 242 13 320 -11z"
                                        />
                                        <path
                                            d="M70 1148 c-39 -27 -70 -81 -70 -122 0 -39 34 -149 74 -244 142 -331
                                        415 -594 751 -725 178 -69 237 -72 299 -14 56 53 61 146 10 206 -18 22 -49 37
                                        -119 60 -291 94 -502 271 -635 534 -27 53 -59 130 -71 170 -12 39 -30 85 -42
                                        101 -40 57 -138 74 -197 34z"
                                        />
                                    </g>
                                </svg>
                            </div>

                            <div class="help_text">
                                Avvicina il Tag NFC
                            </div>
                            <button @click="chiudiNfcContainer()" class="btn_chiudi_nfc">Annulla</button>
                        </div>
                    </div>
                </Transition>

                <!-- Orario attuale -->
                <div class="timer_clock">{{ time }}</div>
                <div class="today_date">{{ todayDate }}</div>

                <!-- Timbra entrata ed uscita -->
                <div class="buttons_container_presenza" v-if="dipendenteSettings.dipendenti_timbra_entrata_uscita === '1'">
                    <div v-if="dipendenteSettings.dipendenti_dichiara_reparto === '1'" class="selezione_reparto">
                        <ion-select
                            name="tipologia"
                            interface="action-sheet"
                            cancel-text="Annulla"
                            ok-text="Conferma"
                            v-model="repartoSelezionato"
                            value="1"
                            placeholder="Seleziona il reparto in cui timbrare"
                            @ionChange="setReparto($event.target.value)"
                        >
                            <ion-select-option v-for="reparto in reparti" :key="reparto.reparti_id" :value="reparto.reparti_id">
                                {{ reparto.reparti_nome }}
                            </ion-select-option>
                        </ion-select>
                    </div>
                    <div class="btn_container ion-padding-horizontal">
                        <button class="btn_timbratura" @click="handleTimbraEntrata" :disabled="entrataTimbrata">
                            <ion-icon :icon="arrowForward" slot="end"></ion-icon>
                            <span>Timbra entrata</span>
                        </button>
                        <button class="btn_timbratura" @click="handleTimbraUscita" :disabled="uscitaTimbrata">
                            <ion-icon :icon="arrowBack" slot="end"></ion-icon>
                            <span>Timbra uscita</span>
                        </button>
                    </div>
                </div>

                <!-- Crea presenza completa -->
                <div v-if="dipendenteSettings.dipendenti_timbra_posteriori === '1'" class="btn_container ion-padding-horizontal">
                    <button class="btn_presenza" @click="createPresenza">
                        <span>Inserisci presenza</span>
                    </button>
                </div>

                <div class="btn_container ion-padding-horizontal">
                    <button v-if="dipendenteSettings.dipendenti_timbra_qrcode === '1'" class="btn_nfc" @click="openQrCode()">
                        Timbra con QR
                    </button>
                    <button class="btn_nfc" @click="nfcScan()">
                        Timbra con NFC
                    </button>
                </div>

                <div v-if="dipendenteSettings.dipendenti_reperibilita === '1'" class="btn_container ion-padding-horizontal">
                    <button class="btn_reperibilita" @click="openReperibilita()">
                        Dichiara reperibilità
                    </button>
                </div>

                <div v-if="!presenze.length" class="no_presenze_container">
                    <div class="no_presenze">Nessuna presenza inserita</div>
                </div>

                <div v-else>
                    <div class="container_presenze">
                        <div v-for="timbratura in presenze" :key="timbratura.presenze_id" class="presenza" @click="apriStraordinario(timbratura)">
                            <div class="presenza_info">
                                <div class="title">{{ dateFormat(timbratura.presenze_data_inizio) }} {{ printCustomer(timbratura) }}</div>

                                <div class="orari">
                                    Ingresso: {{ timbratura.presenze_ora_inizio }} - Uscita:
                                    {{ timbratura.presenze_ora_fine || "-" }}
                                </div>

                                <div v-if="timbratura.presenze_straordinario > 0" class="straordinario">
                                    Straordinario: {{ parseFloat(timbratura.presenze_straordinario).toFixed(2) }}
                                </div>
                            </div>

                            <!--                             <div class="status">
                                <span class="straordinario_button" @click="apriStraordinario(timbratura)">
                                    Straordinario
                                </span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonButtons,
    IonInput,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    onIonViewWillEnter,
    menuController,
    modalController,
} from "@ionic/vue";
import { refresh, menu, arrowBack, arrowForward } from "ionicons/icons";

import { computed, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";

//Lettura QR
import { QrStream } from "vue3-qr-reader";

import moment from "moment";

import { openToast } from "@/services/toast";

import apiPresenze from "@/services/presenze";
import apiReparti from "@/services/reparti";
import apiLogin from "@/services/login";

import Reperibilita from "@/components/Reperibilita.vue";
import ModalCreaPresenza from "@/components/ModalCreaPresenza.vue";

export default {
    name: "Presenze",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonTextarea,
        QrStream,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const richieste = ref([]);

        const userID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_id;
        const user = JSON.parse(localStorage.getItem("userPlanInfo"));
        //Get dipendente settings
        /* const {
            dipendenti_consenti_straordinari,
            dipendenti_dichiara_reparto,
            dipendenti_reperibilita,
            dipendenti_timbra_qrcode,
            dipendenti_geolocalizzazione,
            dipendenti_timbra_posteriori,
        } = user; */

        //Nuovi campi per timbratura
        const presenze = ref([]);
        const entrataTimbrata = ref(false);
        const uscitaTimbrata = ref(false); //opposto del timbra entrata

        const statusShowItem = ref({});
        //Real time clock
        const timer = ref(null);
        const time = ref(null);

        /**
         * ! Salva dati del dipendente
         */
        const dipendenteSettings = ref({
            dipendenti_timbra_entrata_uscita: null,
            dipendenti_consenti_straordinari: null,
            dipendenti_dichiara_reparto: null,
            dipendenti_reperibilita: null,
            dipendenti_timbra_qrcode: null,
            dipendenti_geolocalizzazione: null,
            dipendenti_timbra_posteriori: null,
        });

        async function getDipendenteData() {
            const res = await apiLogin.getUserDipendente(userID);
            //console.log(res);
            if (res.status === 200 && res.data.status === 0) {
                const {
                    dipendenti_timbra_entrata_uscita,
                    dipendenti_consenti_straordinari,
                    dipendenti_dichiara_reparto,
                    dipendenti_reperibilita,
                    dipendenti_timbra_qrcode,
                    dipendenti_geolocalizzazione,
                    dipendenti_timbra_posteriori,
                } = res.data.data[0];
                dipendenteSettings.value = {
                    dipendenti_timbra_entrata_uscita,
                    dipendenti_consenti_straordinari,
                    dipendenti_dichiara_reparto,
                    dipendenti_reperibilita,
                    dipendenti_timbra_qrcode,
                    dipendenti_geolocalizzazione,
                    dipendenti_timbra_posteriori,
                };
                //console.log(dipendenteSettings.value);
            }

            /*             if (dipendenteSettings.value.dipendenti_geolocalizzazione === "1") {
                mustDeclareGeolocation.value = true;
                //await watchGetLocation();
                await getLocation();
            } */
        }

        /**
         * ! Menu handler
         */
        const openMenu = () => {
            menuController.open("app-menu");
        };

        // update the time every second
        function interval() {
            setInterval(() => {
                time.value = Intl.DateTimeFormat(navigator.language, {
                    hour: "numeric",
                    minute: "numeric",
                }).format();
            }, 1000);
        }

        interval();

        const options = { day: "2-digit", month: "long", year: "numeric" };
        const todayDate = new Date().toLocaleString("it-IT", options);

        /**
         * ! Gestisce scansione con NFC per timbrare entrata o uscita
         */
        const scannedNFCValue = ref(null);
        const blockScan = ref(false);

        const showNfcContainer = ref(false);
        function chiudiNfcContainer() {
            scannedNFCValue.value = null;
            blockScan.value = true;
            showNfcContainer.value = false;
        }

        async function handleTimbraturaNfc(reparto_id) {
            blockScan.value = true;
            apiPresenze
                .timbraNFC(dipendenteID, reparto_id)
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        //alert(JSON.stringify(response));
                        if (response.data.tipo === "entrata") {
                            //Inserisco nuova presenza
                            presenze.value = [response.data.data, ...presenze.value];
                            entrataTimbrata.value = true;
                        } else {
                            //Cerco la presenza appena modificata, la aggiorno e abilito di nuovo btn entrata
                            const presenzaDaChiudure = presenze.value.find((element) => element.presenze_id === response.data.data.presenze_id);
                            const presenzaChiusa = response.data.data;
                            if (presenzaDaChiudure) {
                                //console.log(presenzaDaChiudure);
                                presenzaDaChiudure.presenze_data_fine = presenzaChiusa.presenze_data_fine;
                                presenzaDaChiudure.presenze_ora_fine = presenzaChiusa.presenze_ora_fine;
                                presenzaDaChiudure.presenze_straordinario = parseFloat(presenzaChiusa.presenze_straordinario).toFixed(2);

                                entrataTimbrata.value = false;
                            }
                        }
                        openToast(response.data.txt, "toast_success");
                        scannedNFCValue.value = null;
                        //blockScan.value = true;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    alert(error);
                    //blockScan.value = true;
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }

        const nfcScan = async () => {
            //handleTimbraturaNfc("12");
            blockScan.value = false;
            showNfcContainer.value = true;
            try {
                // eslint-disable-line
                const ndef = new NDEFReader(); // eslint-disable-line
                await ndef.scan();
                ndef.addEventListener("reading", ({ message }) => {
                    const decoder = new TextDecoder();

                    for (const record of message.records) {
                        switch (record.recordType) {
                            case "text": {
                                const textDecoder = new TextDecoder(record.encoding);
                                scannedNFCValue.value = textDecoder.decode(record.data);
                                //Se ho scansionato qualcosa e non sono in blocco procedo con la timbratura
                                if (scannedNFCValue.value && !blockScan.value) {
                                    handleTimbraturaNfc(scannedNFCValue.value);
                                }
                                break;
                            }
                            default:
                                openToast("Impossibile effettuare la scansione", "toast_danger");
                        }
                    }
                });
            } catch (error) {
                openToast("La scansione con NFC non è supportata", "toast_danger");
                console.log(error);
                showNfcContainer.value = false;
                blockScan.value = false;
            }
        };

        /**
         * ! Apre modale per creare presenza completa con data ed ora inizio e fine + selezione cliente
         */
        async function createPresenza() {
            const modal = await modalController.create({
                component: ModalCreaPresenza,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    presenze.value = [detail.data, ...presenze.value];
                    openToast("Presenza inserita correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Gestione stampa cliente o meno se associato alla presenza
         */
        function printCustomer(timbratura) {
            if (timbratura.customers_company) {
                const trimmedString =
                    timbratura.customers_company.length > 35 ? timbratura.customers_company.substring(0, 32) + "..." : timbratura.customers_company;
                return ` - ${trimmedString}`;
            } else if (timbratura.customers_name && timbratura.customers_last_name) {
                const reference = `${timbratura.customers_name} ${timbratura.customers_last_name}`;
                const trimmedString = reference.length > 35 ? reference.substring(0, 32) + "..." : reference;
                return ` - ${trimmedString}`;
            }
        }

        /**
         * ! Gestione straordinario manuale e richiesta dati reparti
         */
        const reparti = ref([]);
        const repartoSelezionato = ref(null);
        async function loadReparti() {
            apiReparti
                .getUserReparti(dipendenteID)
                .then((response) => {
                    if (response.data.length != 0) {
                        reparti.value = response.data;
                    } else {
                        reparti.value = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la richiesta dei reparti", "danger");
                });
        }
        loadReparti();

        /**
         * Setta il reparto, obbligatorio prima di inserire la presenza
         * @param reparto_id string of selected reparto
         */
        function setReparto(reparto_id) {
            repartoSelezionato.value = reparto_id;
        }

        const showStraordinario = ref(false);
        const selectedPresenza = ref(null);

        function apriStraordinario(presenza) {
            if (presenza.presenze_data_fine || presenza.presenze_data_fine == "") {
                selectedPresenza.value = { ...presenza };
                showStraordinario.value = true;
            } else {
                openToast("Potrai inserire lo staordinario solamente dopo aver chiuso la presenza", "toast_danger");
            }
        }
        function chiudiStraordinario() {
            selectedPresenza.value = null;
            showStraordinario.value = false;
        }

        /**
         * ! EDIT PRESENZA
         */

        function editPresenza() {
            //se devo modificare straordinario faccio controllo che sia positivo
            if (
                dipendenteSettings.value.dipendenti_consenti_straordinari === "1" &&
                selectedPresenza.value &&
                (selectedPresenza.value.presenze_straordinario < 0 || selectedPresenza.value.presenze_straordinario === "")
            ) {
                openToast("Devi inserire un valore maggiore o uguale a 0 per salvare lo straordinario", "toast_danger");
                return;
            } else {
                const presenzaId = selectedPresenza.value.presenze_id;
                const straordinario = selectedPresenza.value.presenze_straordinario;
                const note = selectedPresenza.value.presenze_note ? selectedPresenza.value.presenze_note : "";

                apiPresenze
                    .editPresenza(presenzaId, straordinario, note)
                    .then((response) => {
                        //console.log(response);
                        if (response.data.status === 0) {
                            const res = response.data.data[0];
                            //Aggiorno presenza appena modificata e chiudo la modale
                            const presenzaModificata = presenze.value.find((element) => element.presenze_id === res.presenze_id);
                            if (presenzaModificata) {
                                presenzaModificata.presenze_straordinario = res.presenze_straordinario;
                                presenzaModificata.presenze_note = res.presenze_note;
                                openToast("Presenza modificata correttamente", "toast_success");
                                showStraordinario.value = false;
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        openToast("Errore durante la modifica della presenza", "toast_danger");
                    });
            }
        }

        /**
         * ! DELETE SELECTED PRESENZA
         */
        async function deletePresenza(presenza_id) {
            try {
                const response = await apiPresenze.deletePresenza(presenza_id, userID);
                console.log(response);
                if (response.status === 200 && response.data.status === 8) {
                    openToast(response.data.message, "toast_danger");
                } else if (response.status === 200 && response.data.status === 0) {
                    presenze.value = presenze.value.filter((presenza) => presenza.presenza_id != presenza_id);
                    selectedPresenza.value = null;
                    showStraordinario.value = false;
                    openToast("Presenza cancellata correttamente", "toast_success");
                } else {
                    openToast("Errore durante la cancellazione della presenza", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la cancellazione della presenza", "toast_danger");
            }
        }

        /**
         * ! Apre pagiuna per inserire la reperibilità e visualizzare quelle inserite in elenco,
         * ! Se comunicata nell'ultima ora mostro button per poterla eliminare
         */
        async function openReperibilita() {
            const modal = await modalController.create({
                component: Reperibilita,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //richieste.value = [detail.data, ...richieste.value];
                    openToast("Reperibilita comeunicata correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        //2) CONTROLLO QUALI PULSANTI DISABILITARE
        /**
         * Se ho timbratura di oggi senza presenze_ora_fine vuol dire che ho timbrato SOLO l'entrata -> disabilito entrata
         * Se ho timbratura di oggi ed ha anche presenze_ora_fine vuol dire che ho timbrato entrati -> riabilito entrata per succissivo utilizzo
         */
        function checkTimbrature() {
            /* const presenzeOggi = presenze.value.some(
                (presenza) =>
                    moment(presenza.presenze_data_inizio).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
                    (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")
            ); */
            const presenzeAperte = presenze.value.some((presenza) => {
                const dataInizio = moment(presenza.presenze_data_inizio).format("YYYY-MM-DD");
                const dataFine = moment(presenza.presenze_data_fine).format("YYYY-MM-DD");

                return (
                    (dataInizio === moment().format("YYYY-MM-DD") && (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")) ||
                    (dataInizio ===
                        moment()
                            .subtract(1, "days")
                            .format("YYYY-MM-DD") &&
                        (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === ""))
                );
            });
            if (presenzeAperte) {
                entrataTimbrata.value = true;
            } else {
                entrataTimbrata.value = false;
            }
        }

        //1) SCARICO TIMBRATURE EFFETTUATE e controllo se disabilitare entrata
        async function loadTimbrature() {
            loading.value = true;
            apiPresenze
                .getTimbrature(dipendenteID)
                .then((response) => {
                    //console.log("TIMBRATURE EFFETTUATE: ", response);
                    presenze.value = response;
                    if (response.length > 0) {
                        checkTimbrature();
                    } else {
                        entrataTimbrata.value = false;
                        uscitaTimbrata.value = false;
                    }
                    //checkTimbrature(response);
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la richiesta delle presenze", "danger");
                })
                .finally(() => {
                    statusShowItem.value = {};
                    loading.value = false;
                });
        }

        /**
         * ! CONTROLLO presenze
         * * Se trovo almeno una presenza di oggi senza data fine vuol dire che sto timbrando l'uscita (posso averne fatte altre oggi ma sempre entrando ed uscendo)
         * * Se non la trovo vuol dire che sto timbrando l'entrata (o è la prima/unica della giornata o sto entrando nuovamente)
         */
        const confirmationText = ref("");
        function checkPresenze() {
            const presenzeOggi = presenze.value.some(
                (presenza) =>
                    moment(presenza.presenze_data_inizio).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
                    (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")
            );
            const orario = moment().format("HH:mm");

            if (presenzeOggi) {
                confirmationText.value = `Cliccando su "Salva" confermi di timbrare l'uscita alle ore ${orario}`;
            } else {
                confirmationText.value = `Cliccando su "Salva" confermi di timbrare l'entrata alle ore ${orario}`;
            }
        }

        /**
         * QR Code handler
         */
        const state = reactive({
            data: null,
        });
        const showQrCode = ref(false);
        const disabledQrCodeButton = ref(false);

        function onDecode(data) {
            state.data = data;
            if (data) {
                //Stampo informazioni per avvisare il dipendente della timbratura che sta per effettuare (entrata o uscita)
                //checkTimbrature();
                checkPresenze();
            }
        }

        function openQrCode() {
            showQrCode.value = true;
        }
        function chiudiQrCode() {
            showQrCode.value = false;
            state.data = null;
            confirmationText.value = "";
            disabledQrCodeButton.value = false;
        }
        function handleQrCodeTimbratura() {
            //console.log(state.data);
            if (state.data) {
                //let qrValue = state.data.slice(-5);
                let qrValue = state.data;
                disabledQrCodeButton.value = true;

                apiPresenze
                    .scanQrCode(qrValue, dipendenteID)
                    .then((response) => {
                        if (response.data.status === 0) {
                            //error
                            openToast(response.data.txt, "toast_danger");
                        } else {
                            if (response.data.tipo === "entrata") {
                                //aggiungo nuova presenza
                                presenze.value = [response.data.data, ...presenze.value];
                                entrataTimbrata.value = true;
                            } else {
                                //devo modificare presenza e chiudo la modale
                                const presenzaModificata = presenze.value.find((element) => element.presenze_id === response.data.data.presenze_id);
                                if (presenzaModificata) {
                                    presenzaModificata.presenze_data_fine = response.data.data.presenze_data_fine;
                                    presenzaModificata.presenze_ora_fine = response.data.data.presenze_ora_fine;
                                    presenzaModificata.presenze_straordinario = response.data.data.presenze_straordinario;
                                }
                                entrataTimbrata.value = false;
                            }
                            openToast(response.data.txt, "toast_success");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        openToast("Errore durante la scansione del codice QR", "toast_danger");
                    })
                    .finally(() => {
                        state.data = null;
                        showQrCode.value = false;
                        qrValue = null;
                        confirmationText.value = "";
                        disabledQrCodeButton.value = false;
                    });
            } else {
                openToast("Inquadra il codice QR prima di salvare la presenza", "toast_danger");
            }
        }

        /**********************************************************************************************************************/
        //5) UTILS
        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }
        /**
         * Format date from YYYY/MM/DD HH:mm:ss to HH:mm
         */
        function timeFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("HH:mm");
            } else {
                return "-";
            }
        }

        //Set correct background for richieste status
        const statoReperibilita = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 0) {
                    //attesa approvazione
                    className = "badge_danger";
                } else if (statusId == 1) {
                    //approvato
                    className = "badge_success";
                }
                return className;
            };
        });

        /**********************************************************************************************************************/
        //4A) TIMBRATURA USCITA - Edit della presenza di oggi e senza presenze_ora_fine
        function timbraUscita(uscita_data, uscita_ora) {
            const ora_uscita = uscita_ora;
            const data_uscita = uscita_data;
            const data = new FormData();
            data.append("presenze_dipendente", dipendenteID);
            data.append("presenze_data_inizio", data_uscita);
            data.append("presenze_ora_fine", ora_uscita);

            apiPresenze
                .chiudiPresenza(dipendenteID, ora_uscita, "APP")
                .then((response) => {
                    console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        openToast(response.data.txt, "toast_success");
                        //Cerco la presenza appena modificata, la aggiorno e abilito di nuovo btn entrata
                        const presenzaDaChiudure = presenze.value.find((element) => element.presenze_id === response.data.uscita.presenze_id);
                        const presenzaChiusa = response.data.uscita;
                        if (presenzaDaChiudure) {
                            //console.log(presenzaDaChiudure);
                            presenzaDaChiudure.presenze_data_fine = presenzaChiusa.presenze_data_fine;
                            presenzaDaChiudure.presenze_ora_fine = presenzaChiusa.presenze_ora_fine;
                            presenzaDaChiudure.presenze_straordinario = parseFloat(presenzaChiusa.presenze_straordinario).toFixed(2);

                            entrataTimbrata.value = false;
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }
        //4) SALVO PRESENZA USCITA
        /**
         * Se ho effettuato una timbratura in entrata (la presenza SENZA ora_fine è quella di oggi)
         * allora posso fare una timbratura in uscita --> edit presenze impostando ora e data fine
         * altrimenti mostro errore
         */
        function handleTimbraUscita() {
            const data_uscita = moment().format("YYYY-MM-DD");
            const ora_uscita = moment().format("HH:mm");
            timbraUscita(data_uscita, ora_uscita);
        }

        /* ===================================================================================================================== */
        const mustDeclareGeolocation = ref(false);
        const rejectedGeolocation = ref(false);
        /**
         * ! Get current geo coordinates
         */
        const position = ref(null);
        const latitiude = ref(null);
        const longitude = ref(null);
        const currentPosition = reactive({
            latitude: null,
            longitude: null,
            accuracy: null,
        });
        function onSuccess(pos) {
            console.log(pos);
            const crd = pos.coords;
            //position.value = pos.coords;
            /* console.log(position.value);
            console.log("Your current position is:");
            console.log(`Latitude : ${crd.latitude}`);
            console.log(`Longitude: ${crd.longitude}`);
            console.log(`More or less ${crd.accuracy} meters.`); */

            currentPosition.latitude = crd.latitude;
            currentPosition.longitude = crd.longitude;
            currentPosition.accuracy = crd.accuracy;

            console.log(position.value);
            latitiude.value = `${crd.latitude}`;
            longitude.value = `${crd.longitude}`;
            console.log(position.value);
        }
        function onError(err) {
            console.log("errore ", err);
            //1: PERMISSION_DENIED
            if (err.code === 1) {
                rejectedGeolocation.value = true;
                openToast("Permessi negati per ottenere la posizione", "toast_danger");
            } else {
                //2: POSITION_UNAVAILABLE, 3: TIMEOUT
                openToast("Impossibile ottenere la posizione attuale", "toast_danger");
                rejectedGeolocation.value = false;
            }
            openToast("Impossibile ottenere la posizione attuale", "toast_danger");
            //sblocco button timbra entrata
            entrataTimbrata.value = false;

            console.warn(`ERROR(${err.code}): ${err.message}`);
        }

        //3A) SALVA PRESENZA IN ENTRATA
        function timbraEntrata(entrata_data, entrata_ora, reparto, latitude, longitude) {
            const ora_entrata = entrata_ora;
            const data_entrata = entrata_data;

            apiPresenze
                .timbraEntrata(dipendenteID, ora_entrata, reparto, latitude, longitude, "APP")
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        entrataTimbrata.value = false;
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        openToast(response.data.txt, "toast_success");
                        presenze.value = [response.data.entrata, ...presenze.value];
                        entrataTimbrata.value = true;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    entrataTimbrata.value = false;
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }

        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        /*function getPosition(options) {
            return new Promise((onSuccess, onError) => navigator.geolocation.getCurrentPosition(onSuccess, onError, geoOptions));
        }*/
        async function getPosition(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        // L'utente ha fornito l'autorizzazione alla geolocalizzazione
                        //console.log(position);
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        return resolve(position);
                    },
                    function(error) {
                        //console.log(error);
                        if (error.code === error.PERMISSION_DENIED) {
                            //console.log("L'utente ha negato l'autorizzazione alla geolocalizzazione.");
                            entrataTimbrata.value = false;
                            openToast("Geolocalizzazione spenta oppure hai negato l'autorizzazione.", "toast_danger");
                        } else {
                            // Si è verificato un altro tipo di errore durante la geolocalizzazione
                            //console.log("Si è verificato un errore durante la geolocalizzazione.");
                            entrataTimbrata.value = false;
                            openToast("Si è verificato un errore durante la geolocalizzazione.", "toast_danger");
                        }
                        return reject(error);
                    }
                )
            );
        }

        //3) GESTISCE PRESENZA IN ENTRATA ( --------> [HR - APP] - Presenza in entrata ed orari calendario )
        async function handleTimbraEntrata() {
            const data_ordierna = moment().format("YYYY-MM-DD");
            const ora_entrata = moment().format("HH:mm");

            //Se devo comunicare prima il reparto controllo di averlo selezionato
            if (dipendenteSettings.value.dipendenti_dichiara_reparto === "1" && !repartoSelezionato.value) {
                openToast("Prima di timbrare devi selezionare il reparto di riferimento", "toast_danger");
                return;
            }

            //Se devo comunicare le coordinate
            if (dipendenteSettings.value.dipendenti_geolocalizzazione === "1") {
                mustDeclareGeolocation.value = true;
                entrataTimbrata.value = true;
                const pos = await getPosition(geoOptions);
                console.log(pos);
                if (pos && pos.coords) {
                    timbraEntrata(data_ordierna, ora_entrata, repartoSelezionato.value, pos.coords.latitude, pos.coords.longitude, "APP");
                } /*  else {
                    entrataTimbrata.value = false;
                    openToast("Non è stato possibile ottenere la propria posizione", "toast_danger");
                    return;
                } */
            } else if (dipendenteSettings.value.dipendenti_geolocalizzazione === "1" && !position.value) {
                openToast("Prima di timbrare devi permettere e/o attivare la geolocalizzazione", "toast_danger");
                return;
            } else {
                //Se nel mio profilo c'è un orario di inizio e fine per la giornata di oggi salva l'entrata altrimenti errore
                await timbraEntrata(data_ordierna, ora_entrata, repartoSelezionato.value, position.value);
            }
        }

        /* ===================================================================================================================== */
        //1) SCARICO TIMBRATURE EFFETTUATE e controllo se disabilitare entrata
        /*onMounted(() => {
            loadTimbrature();
        });*/

        /* Ad ogni accesso alla pagina controllo se disabilitare il pulsante */
        onIonViewWillEnter(() => {
            //Richiesta ai dati del dipendente per abilitare/disabilitare button
            getDipendenteData();
            loadTimbrature();
        });

        onBeforeUnmount(() => {
            clearInterval(timer.value);
        });

        return {
            loading,
            dateFormat,
            timeFormat,
            refresh,
            richieste,
            loadTimbrature,
            //Presenze
            presenze,
            handleTimbraEntrata,
            handleTimbraUscita,
            entrataTimbrata,
            uscitaTimbrata,
            //Reperibilità
            statoReperibilita,
            statusShowItem,
            //setStraordinario,
            //reperibilita,
            menu,
            openMenu,
            time,
            arrowBack,
            arrowForward,
            //Pagina reperibilita
            openReperibilita,
            //Straordinario
            apriStraordinario,
            chiudiStraordinario,
            selectedPresenza,
            showStraordinario,
            //salvaStraordinario,
            editPresenza,
            todayDate,
            //QR code data,
            openQrCode,
            showQrCode,
            QrStream,
            ...toRefs(state),
            onDecode,
            chiudiQrCode,
            handleQrCodeTimbratura,
            confirmationText,
            disabledQrCodeButton,
            //Creazione presenza completa
            createPresenza,
            printCustomer,
            //Selezione reparto per effettuare la presenza
            repartoSelezionato,
            reparti,
            setReparto,
            //MOSTRA / NASCONDI BUTTON IN BASE AI SETTINGS DEL DIPENDENTE
            dipendenteSettings,
            deletePresenza,
            //NFC
            nfcScan,
            blockScan,
            showNfcContainer,
            chiudiNfcContainer,
        };
    },
};
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_presenze_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-top: 8px;
}
.btn_presenza_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 8px;
}
.btn_presenza {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0a90d4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Timbrature */
.timbratura_container {
    width: 100%;
}

.timbratura_first_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}

.timbratura_data {
    font-weight: 500;
    font-size: 14px;
}

.data_richiesta .orari {
}

.reperibilita {
    width: 90px;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 4px;
    color: #f1f5f9;
    display: flex;
    justify-content: center;
}

.dichiarata {
    background: #16a34a;
}

.non_dichiarata {
    background: #dc2626;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    /* to be used for new richiesta btn */
    min-height: 100%;
    background: #f8fafc;
}

.no_presenze_container {
    width: 100%;
    padding: 16px;
}

.no_presenze {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

ion-button {
    --color: #ffffff;
}
ion-button.btn_custom {
    --background: #16a34a;
    --background-activated: #15803d;
    --background-hover: #15803d;
    --background-focused: #15803d;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    width: 45%;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

ion-button.btn_reperibilita {
    /*--background: #0ea5e9;*/
    --background: #0087ca;
    --background-activated: #0576ae;
    --background-hover: #0576ae;
    --background-focused: #0576ae;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.container_presenze {
    width: 100%;
    padding: 16px;
    padding-top: 0px;
}

.presenza {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.presenza_info .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.presenza_info .orari {
    font-size: 12px;
    color: #6b7280;
}
.presenza_info .straordinario {
    margin-top: 4px;
    font-size: 12px;
    color: #6b7280;
}

.presenza .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.presenza .badge_success {
    background-color: #22c55e;
}

.presenza .badge_warning {
    background-color: #f97316;
}

.presenza .badge_danger {
    background-color: #ef4444;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

.timer_clock {
    width: 100%;
    padding: 20px;
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    color: #0a90d4;
    font-weight: bold;
    font-size: 42px;
}
.today_date {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #0a90d4;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 16px;
}

/* Btns timbratura */
.btn_nfc {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1dacf4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_nfc:disabled {
    background-color: rgba(10, 144, 212, 0.5);
}
.btn_timbratura {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0a90d4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_timbratura:active {
    background-color: #086fa3;
    color: #ffffff;
}
.btn_timbratura span {
    margin-left: 10px;
}
.btn_timbratura:disabled {
    background-color: rgba(10, 144, 212, 0.5);
}
/* Btn reperibilita */
.btn_reperibilita {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #1dacf4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_reperibilita:active {
    background-color: #0a90d4;
    color: #ffffff;
}

/** Modale per inserire straoridnario su ogni singola presenza */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.presenza .straordinario_button {
    font-size: 10px;
    font-weight: 600;
    /*color: #ffffff;*/
    color: #0a90d4;
    /*background-color: #0a90d4;*/
    padding: 4px 8px;
    border-radius: 4px;
}
.modal_straordinario {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 16px;
    z-index: 10;
}
.input_container {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
}
.input_container .field {
    margin-bottom: 24px;
}
.input_container .title {
    font-weight: 500;
    margin-bottom: 12px;
}
.input_container .title_qr {
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
}
.btn_salva_straordinario {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #0a90d4;
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    margin-top: 24px;
}
.btn_salva_straordinario:disabled {
    /* background-color: rgba(10, 144, 212, 0.5);
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1); */
    opacity: 0.75;
    transition: all 0.15s ease-in;
}
.btn_chiudi_straordinario {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: rgb(220 38 38);
    background-color: rgb(254 202 202);
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    margin-top: 12px;
}

/**
* Qr Code style
 */
.qr_code {
    /* height: 400px;
  width: 400px;*/
    height: 300px;
    margin: auto;
}
/* .frame {
  border-style: solid;
  border-width: 2px;
  border-color: #0a90d4;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
} */

.confirmation_text {
    margin-top: 16px;
    font-weight: bold;
}

.selezione_reparto {
    display: flex;
    justify-content: center;
    padding: 8px 16px;
}
.selezione_reparto ion-select {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

.buttons_container_presenza {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.btn_qrcode {
    width: 100%;
}

/* Modal NFC Tag */

.modal_nfc {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 16px;
    z-index: 10;
}
.nfc_container {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
}
.nfc_container .field {
    margin-bottom: 24px;
}
.nfc_container .title {
    font-weight: 500;
    margin-bottom: 12px;
}
.nfc_container .title_nfc {
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
}
.nfc_container .help_text {
    color: #6b7280;
    text-align: center;
    margin-bottom: 16px;
}
.svg_container {
    padding: 0 52px;
    margin: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.svg_container svg {
    max-width: 100px;
}

.btn_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.btn_chiudi_nfc {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: rgb(220 38 38);
    background-color: rgb(254 202 202);
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    margin-top: 12px;
}
</style>
